class IframeConnection {

  targetFrame = null
  events = []
  callbackRouterInstance = null
  
  constructor (targetFrame) {
    if (!targetFrame) { throw new Error('IframeConnection constructor require\'s target window instance as first argument.'); }
    this.targetFrame = targetFrame;
    this.callbackRouterInstance = this.callbackRouter.bind(this);

    window.addEventListener('message', this.callbackRouterInstance);
  }
  // Подписка события на коллбек
  on (eventType, callback) {
    this.events[eventType] = callback;
  }
  // Отписка события от коллбека
  off (eventType) {
    delete this.events[eventType];
  }
  // Очиска всех подписок
  purgeAll () {
    this.events = [];
    window.removeEventListener('message', this.callbackRouterInstance)
  }
  // Метод вызывающий коллбек соответствующий полученному типу события
  callbackRouter (event) {
    if (
      !event?.data?.type ||
      event.origin !== window.location.origin
    ) return false;
    
    this.events[event.data.type]?.(event);
  }
  // Метод отправления сообщения в целевой iframe
  async postMessage (type, payload) {
    this.targetFrame?.postMessage({ type, payload }, window.location.origin);
  }
}

export default IframeConnection