<script>
import IframeConnection from '@/plugins/classes/IframeConnection'
let iConnection = null;

export default {
  beforeDestroy () {
    iConnection?.purgeAll();
  },
  methods: {
    onFrameLoaded (event) {
      const iframeTag = event?.path?.find(el => el.tagName === 'IFRAME') || event?.target;
      const frame = iframeTag?.contentWindow;

      if (!iframeTag || !frame) { throw new Error('Wrong data was given from event payload.') }

      iConnection = new IframeConnection(frame);

      iConnection.on('request-session', this.requestSessionHandler);
      iConnection.on('request-logout', this.requestLogoutHandler);

      this.requestSessionHandler();
    },
    // Обработчик события 'request-session'
    requestSessionHandler () {
        iConnection.postMessage(
            'set-session',
            {
                access_token: this.$store.state.user.access_token,
                profile: this.$store.state.user.profile,
                educont_info: this.$store.state.user.educont_info
            }
        );
    },
    // Обработчик события 'request-logout'
    async requestLogoutHandler () {
        await this.$store.dispatch('user/logout');
        window.location.href = '/';
    }
  }
}
</script>